import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import { StaticImage } from 'gatsby-plugin-image';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  placementGrid: {
    display: 'flex',
  },
  placementGridItemMiddle: {
    margin: `0 ${theme.spacing(3)}`,
  },
  coverImage: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      maxWidth: 500,
    },
  },
}));

const Multiscale = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={4}>
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos="fade-up"
        >
          <div
            data-aos="flip-right"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <StaticImage
              src="images/desertrose.jpg"
              alt="Digital model of a desert rose"
              placeholder="blurred"
              className={classes.coverImage}
            />
          </div>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
          data-aos="fade-up"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionHeader
                title={
                  <span>
                    Work across a
                    <br />
                    <Typography
                      component="span"
                      variant="inherit"
                      color="primary"
                    >
                      wide{' '}
                    </Typography>
                    range of{' '}
                    <Typography
                      component="span"
                      variant="inherit"
                      color="primary"
                    >
                      scales
                    </Typography>
                    .
                  </span>
                }
                subtitle="From sub-mm to regional scale mapping in a single 3D view. No need to swap views to work at different scales, the advanced visualisation approaches used in VRGS give you the flexibility and power you need."
                align="left"
                fadeUp
                disableGutter
                titleVariant="h3"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Multiscale.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Multiscale;
