import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { colors, Divider } from '@mui/material';
import Section from 'components/organisms/Section/Section';
import SectionAlternate from 'components/organisms/SectionAlternate/SectionAlternate';
import {
  FaultsAndFractures,
  Stereonets,
  Multiscale,
  Advantages,
  VideoSection,
} from './components';
import SEO from '../../components/SEO';
import { advantages } from './data';

export const Head = () => (
  <SEO description="Structural Geology in VRGS. Map geological faults, folds and fractures across scales with interactive stereonet displays." />
);

const useStyles = makeStyles((theme) => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  shape: {
    background: theme.palette.alternate.main,
    borderBottomRightRadius: '50%',
    borderBottom: `1px solid ${colors.grey[200]}`,
  },
}));

const StructuralGeology = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.shape}>
        <Section className={classes.pagePaddingTop}>
          <VideoSection
            videoSrc="https://www.youtube.com/embed/E1IRnSMS-iY"
            videoTitle="Structural Geology with VRGS."
            videoSubtitle="By combining the use of surface attributes to assist interpretation, and the integrated stereonet display to visualise and analyse structural data, you can interpret complex structural datasets efficiently."
          />{' '}
        </Section>
        <Section className={classes.pagePaddingTop}>
          <FaultsAndFractures />
        </Section>
        <Section className={classes.sectionNoPaddingTop}>
          <Multiscale />
        </Section>
        <Section className={classes.sectionNoPaddingTop}>
          <Stereonets />
        </Section>
        <SectionAlternate>
          <Advantages data={advantages} />
        </SectionAlternate>
      </div>
      <Divider />
    </div>
  );
};

export default StructuralGeology;
