import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import SVGImage from 'components/atoms/SVGImage/SVGImage';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import DescriptionListIcon from 'components/organisms/DescriptionListIcon/DescriptionListIcon';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  logoImage: {
    maxWidth: '50px',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderStyle: 'none',
  },
}));

const Advantages = (props) => {
  const { data, className, ...rest } = props;
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={
          <span>
            Interpret both{' '}
            <Typography component="span" variant="inherit" color="primary">
              structural geology
            </Typography>{' '}
            and{' '}
            <Typography component="span" variant="inherit" color="primary">
              sedimentology
            </Typography>{' '}
            in VRGS
          </span>
        }
        subtitle="VRGS : the integrated digital outcrop geology solution."
        fadeUp
      />
      <Grid container spacing={4}>
        {data.map((item, index) => (
          <Grid key={index} item xs={12} sm={6} md={6} data-aos="fade-up">
            <DescriptionListIcon
              title={item.title}
              subtitle={item.subtitle}
              icon={
                <SVGImage
                  className={classes.logoImage}
                  src={item.icon}
                  alt="The integrated digital outcrop modelling solution"
                />
              }
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Advantages.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Advantages;
