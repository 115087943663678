import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import { StaticImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      maxWidth: 500,
    },
  },
  root: {
    width: '100%',
    height: '100%',
  },
  dBlock: {
    display: 'block',
  },
}));

const Stereonets = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid
        container
        justifyContent="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title={
              <span>
                Interactive
                <br />
                <Typography component="span" variant="inherit" color="primary">
                  stereonet
                </Typography>{' '}
                displays
              </span>
            }
            subtitle="Stereonets are essential for understanding structural data. Watch your stereonet display update in realtime as you interpret faults and fractures, or classify point clouds and mesh data directly from the stereonet view."
            align="left"
            disableGutter
            titleVariant="h3"
          />
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <div
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <StaticImage
              src="images/attributes.jpg"
              alt="Atttribute analysis for point clouds and meshes"
              placeholder="blurred"
              className={classes.image}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Stereonets.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Stereonets;
